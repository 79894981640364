
import Keycloak from 'keycloak-js';

export function CheckLogin() {
    return new Promise(function (resolve, reject) {
        var keycloakToken = sessionStorage.getItem('token');
        if (keycloakToken === null || keycloakToken === "") {
            try {
                let keycloak = Keycloak('keycloak.json');
                keycloak.redirectUri = window.location.href;

                keycloak.init({ onLoad: 'login-required', "checkLoginIframe": false }).then((authenticated) => {
                    console.log("Authenticated: " + authenticated);

                    setSessionData(keycloak);

                    resolve(authenticated);
                }).catch(() => {
                    console.error("Authentication Failed");
                });
            } catch (error) {
                reject(error);
            }
        } else {
            resolve(true);
        }
    });
}

export function CheckLoginRedirect() {
    return new Promise(function (resolve, reject) {
        var keycloakToken = sessionStorage.getItem('token');
        if (keycloakToken === null || keycloakToken === "") {
            try {
                let keycloak = Keycloak('keycloak.json');

                keycloak.init({
                    onLoad: 'check-sso',
                    silentCheckSsoRedirectUri: window.location.origin + '/CheckSSO'
                }).then((authenticated) => {
                    if (!authenticated) {
                        let loginUrl = keycloak.createLoginUrl({ redirectUri: window.location.href });
                        window.location.href = loginUrl;
                    } else {
                        setSessionData(keycloak);
                    }

                    resolve(authenticated);
                });
            } catch (error) {
                reject(error);
            }
        } else {
            resolve(true);
        }
    });
}

export function CheckSSO() {
    return new Promise(function (resolve, reject) {
        try {
            let keycloak = Keycloak('keycloak.json');
            keycloak.init({
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/CheckSSO'
            }).then((authenticated) => {
                console.log("Authenticated: " + authenticated);

                //If SSO authentication was successful set keycloak token in session for api calls, and set token refresh interval
                if (authenticated) {
                    setSessionData(keycloak);

                    console.debug("SSO Token Interval");
                    setInterval(() => {
                        keycloak.updateToken(70).then((refreshed) => {
                            if (refreshed) {
                                setSessionData(keycloak);

                                console.debug('Token refreshed' + refreshed);
                            } else {
                                console.warn('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                            }
                        }).catch(() => {
                            console.error('Failed to refresh token');
                        });
                    }, 60000);
                }
                resolve(authenticated);
            });
        } catch (error) {
            reject(error);
        }
    });
}

function setSessionData(keycloak) {
    sessionStorage.setItem('token', keycloak.token);
    sessionStorage.setItem('refreshToken', keycloak.refreshToken);
    sessionStorage.setItem('username', keycloak.idTokenParsed.preferred_username);
}

export function TokenExists() {
    let keycloakToken = sessionStorage.getItem('token');
    return keycloakToken !== null && keycloakToken !== "";
}