import { createSlice } from '@reduxjs/toolkit';

export const filterCriteriaSlice = createSlice({
    name: 'filterCriteria',
    initialState: {
        value: ""
    },
    reducers: {
        dispatchFilterCriteria: (state, action) => {
            state.value = action.payload
        }
    }
});

export const { dispatchFilterCriteria } = filterCriteriaSlice.actions;
export const selectFilterCriteria = state => state.filterCriteria.value;

export default filterCriteriaSlice.reducer;