import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import $ from 'jquery';

export default function BreadCrumb(props) {
    let [breadCrumb, setBreadCrumb] = useState('');

    const createBreadCrumb = (path) => {
        // map the path to a node alias while the kentico API is broken and does not search by alias properly

        if (path.includes("knowledge-centre")) path = "/knowledge-centre";
        if (path.includes("online-store")) path = "/online-store";
        if (path.includes("mentoring-program")) path = "/mentoring-program";
        if (path.includes("firm-profile")) path = "/firm-profile";
        if (path.includes("/audit-portal")) path = "/audit-portal";
        if (path.includes("/registrant-audit-portal")) path = "/registrant-audit-portal";

        let mapPathsToNodesDict = {
            "/application-status-portal": "/how-to-apply/check-application-status",
            "/account-dashboard": "/account/account-dashboard",
            "/events": "/Events",
            "/event": "/Events",
            "/external-event": "/Events",
            "/knowledge-centre": "/registrants/continuing-education/knowledge-centre",
            "/cep-reporting": "/registrants/continuing-education/ce-reporting-system",
            "/student-renewal": "/how-to-apply/programs-and-resources/student-program",
            "/career-ad-listings": "/Careers/Career-Listings",
            "/job-postings": "/Careers/Career-Listings",
            "/career-ads": "/Careers/Career-Listings",
            "/purchase-prepaid-ads": "/Careers/Career-Listings",
            "/view-job-postings": "/Careers/Career-Listings",
            "/change-legal-name": "/Account/Change-Legal-Name",
            "/membership-confirmation": "/registrants/Registrants-Program",
            "/online-store": "/Seals-and-Certificates",
            "/mentoring-program": "/registrants/registrant-programs/mentoring-program",
            "/firm-profile": "/firms/firm-practice/firm-practice-overview",
            "/audit-portal": "/firms/firm-practice/firm-audit-program",
            "/registrant-audit-portal": "/practice-resources/individual-practice/audit-program"
        };

        const base_url = 'api/KenticoApi/GetBreadCrumb?nodeAliasPath=' + mapPathsToNodesDict[path];
        const init = { method: 'GET', accept: 'application/json', headers: { 'Authorization': ' Bearer ' + sessionStorage.getItem('token') } };
        fetch(`${base_url}`, init)
            .then(response => response.json())
            .then(data => {
                const bc = data;

                setBreadCrumb(bc);
            })
            .catch(ex => {
                console.error(ex);
            });
    };

    useEffect(() => {
        createBreadCrumb(props.path);
    }, []);

    const getBreadCrumb = () => {
        if ({ breadCrumb } != '')
            return { __html: breadCrumb };
    };

    const leftMenuClick = () => {
        let leftMenuButton = $(".left-menu-bar-mobile-button");
        let leftMenu = $(".js-content-page-left");

        if (leftMenu.is(":visible")) {

            leftMenuButton.empty();
            leftMenuButton.append('<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars primary-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path></svg>');
            leftMenu.hide();
            leftMenu.removeClass('lm-fullwidth');

        } else {
            leftMenuButton.empty();
            leftMenuButton.append('<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars primary-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path></svg> <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" class="svg-inline--fa fa-caret-down primary-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"></path></svg>');
            leftMenu.addClass('lm-fullwidth');
            leftMenu.show();
        }
    }

    return (
        <div className="k13-row breadcrumbs-row">
            <div className="k13-column left-menu-bar-mobile">
                <button className="left-menu-bar-mobile-button js-left-menu-bar-mobile-button" type="button" onClick={() => leftMenuClick()}><FontAwesomeIcon icon={solid("bars")} className="primary-icon" /></button>
            </div>
            <div className="k13-column breadcrumbs-column">
                <div className="breadcrumbs" dangerouslySetInnerHTML={getBreadCrumb()}>
                </div>
            </div>
        </div>
    );
}