import { createSlice } from '@reduxjs/toolkit';

export const itemSelectSlice = createSlice({
    name: 'itemSelect',
    initialState: {
        value: ""
    },
    reducers: {
        setItemId: (state, action) => {
            state.value = action.payload
        }
    }
});

export const { setItemId } = itemSelectSlice.actions;
export const selectItemId = state => state.itemSelect.value;

export default itemSelectSlice.reducer;