import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumb from '_common/components/BreadCrumb';
import Footer from '_common/components/Footer';
import LoadingIcon from '_common/components/LoadingIcon';

const Header = lazy(() => import('_common/components/Header'));
const MobileHeader = lazy(() => import('_common/components/MobileHeader'));

const NotificationBanner = lazy(() => import('_common/components/NotificationBanner'));

const Layout = (props) => {

    //If needed add height back in here
    const [width, setWidth] = useState(0);
    const location = useLocation();

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
    };

    return (
        <div className="main-content">
            
            <Suspense fallback={<LoadingIcon />}>
                {width > 1024  &&
                    <>
                        <NotificationBanner />
                        <Header />
                    </>     
                }
                {width <= 1024 &&
                    <>
                        <NotificationBanner />
                        <MobileHeader />
                    </>
                }
            </Suspense>

            <div className="content">
                <div className="content-page">
                    <BreadCrumb path={window.location.pathname} />
                    <div className="k13-row content-page-row">
                        {props.children}
                    </div>
                </div>
            </div>

            <Footer />
                
        </div>
    );
}

export default Layout;