import { configureStore } from '@reduxjs/toolkit'

import itemSelectReducer from 'knowledge-centre/redux-slices/itemSelectSlice';
import filterCriteriaReducer from 'knowledge-centre/redux-slices/filterCriteriaSlice';

const Store = configureStore({
    reducer: {
        itemSelect: itemSelectReducer,
        filterCriteria: filterCriteriaReducer
    },
});

export default Store;
