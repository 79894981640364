import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const LoadingIcon = () => {

    return (
        <div className="row">
            <FontAwesomeIcon icon={regular("circle-notch")} spin className="loading-spinner" />
        </div>
    );
}

export default LoadingIcon;
