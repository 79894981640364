import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { EnvironmentContext } from 'App';

const Footer = () => {

    const { environmentData } = useContext(EnvironmentContext);

    let currentTime = new Date();
    let copyrightInfo = `© ${currentTime.getFullYear()} Engineers and Geoscientists BC`;

    let homeUri = environmentData.egbcURI;

    return (
        <div className="footer">
            <div className="k13-row">
                <div className="k13-column">
                    <ul className="footer-menu">
                        <li><a className="footer-first-link" href={`${homeUri}/Sitemap`} title="Sitemap">Sitemap</a></li>
                        <li><a href={`${homeUri}/Privacy-Policy`} title="Privacy Notice">Privacy Notice</a></li>
                        <li><a href={`${homeUri}/Terms-and-Conditions`} title="Terms &amp; Conditions">Terms &amp; Conditions</a></li>
                    </ul>
                </div>
                <div className="k13-column footer-column-copyright">
                    <ul className="footer-copyright">
                        <li>{copyrightInfo}</li>
                        <li><a className="icon" href="https://twitter.com/EngGeoBC" title="" target="_blank"><FontAwesomeIcon icon={brands("square-x-twitter")} /> </a></li>
                        <li><a className="icon" href="https://www.linkedin.com/company/863438/" title="" target="_blank"><FontAwesomeIcon icon={brands("linkedin")} /></a></li>
                    </ul>
                    <ul className="footer-bizname">
                        <li><div className="bizname">“The business name of the Association of Professional Engineers and Geoscientists of the province of BC”</div></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;
