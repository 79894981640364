import React, { useState, useEffect, Suspense, lazy, createContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from '_app/components/Layout';
import LoadingIcon from '_common/components/LoadingIcon';

import { CheckLogin, CheckSSO } from '_app/components/KeycloakLogin';

import '_common/styles/kendo.scss';

import '_common/styles/common.scss';
import '_common/styles/header.scss';
import 'payments/styles/payment.scss';

import { library, dom } from '@fortawesome/fontawesome-svg-core';//Only import specific icons and only if you can't use the icon macro to import them
import { faBooks, faExternalLinkAlt, faVideo, faCalendarDays } from '@fortawesome/pro-regular-svg-icons'
library.add(faBooks, faExternalLinkAlt, faVideo, faCalendarDays)
dom.watch();

export const KeycloakContext = createContext();
export const EnvironmentContext = createContext();
export const EncryptionContext = createContext();

const KnowledgeCentreApp = lazy(() => import('knowledge-centre/components/KnowledgeCentreApp'));
const CEPReportingApp = lazy(() => import('cep-reporting/components/CEPReportingApp'));
const AuditPortalApp = lazy(() => import('audit-portal/components/AuditPortalApp'));
const FirmProfileApp = lazy(() => import('firm-profile/components/FirmProfileApp'));
const StudentRenewalApp = lazy(() => import('student-renewal/components/StudentRenewalApp'));
const AccountDashboardApp = lazy(() => import('account-dashboard/components/AccountDashboardApp'));
const MembershipConfirmationApp = lazy(() => import('membership-confirmation/components/MembershipConfirmationApp'));
const ChangeLegalNameApp = lazy(() => import('change-legal-name/components/ChangeLegalNameApp'));
const ApplicationStatusPortalApp = lazy(() => import('application-status-portal/components/ApplicationStatusPortalApp'));
const RegistrantAuditPortalApp = lazy(() => import('registrant-audit-portal/components/RegistrantAuditPortalApp'));
const CareerAdApp = lazy(() => import('career-ad/components/CareerAdApp'));
const CareerAdPrepaidApp = lazy(() => import('career-ad/components/CareerAdPrepaidApp'));
const CareerAdListingApp = lazy(() => import('career-ad/components/CareerAdListingApp'));
const CareerAdJobDetailApp = lazy(() => import('career-ad/components/CareerAdJobDetailApp'));
const CareerAdViewPostingApp = lazy(() => import('career-ad/components/CareerAdViewPostingApp'));
const MentoringProgramApp = lazy(() => import('mentoring-program/components/MentoringProgramApp'));
const OnlineStoreApp = lazy(() => import('online-store/components/OnlineStoreApp'));
const EventsApp = lazy(() => import('events/components/EventsApp'));
const EventDetailsApp = lazy(() => import('events/components/EventDetailsApp'));
const ExternalEventDetailsApp = lazy(() => import('events/components/ExternalEventDetailsApp'));
const MembershipApplicationApp = lazy(() => import('membership-application/components/MembershipApplicationApp'));

const LoginPage = () => {
    CheckLogin();
}

const App = () => {

    const [loggedIn, setLoggedIn] = useState(false);
    const [environmentData, setEnvironmentData] = useState({ environment: "", egbcURI: "", egbcToolsURI: "", loginURI: "", egbcAppsURI: "", launchApp: "" });

    const HomePage = () => {
        window.location.href = environmentData.egbcURI;
    }

    //Wait until sso check finishes before rendering
    const [ssoCheckComplete, setSsoCheckComplete] = useState(false);

    useEffect(() => {
        const base_url = 'api/Common/GetEnvironmentData';
        const init = { method: 'GET', accept: 'application/json' };
        fetch(`${base_url}`, init)
            .then(response => response.json())
            .then(data => {
                
                setEnvironmentData({
                    ...environmentData,
                    ...data
                });
            })
            .catch(ex => {
                console.error(ex);
            });

        //Check if user is already logged in before routing them to an app
        CheckSSO().then(authenticated => {
            if (!authenticated) {
                sessionStorage.clear();
            }

            setLoggedIn(authenticated);
            setSsoCheckComplete(true);

        });

    }, []);

    let launch_app = "not-found";
   
    if (environmentData.environment === "Local") {
        launch_app = environmentData.launchApp;
    } 

    /* -- Routing --
        - Only allow routing to Audits/CEP if the user is logged in first, otherwise redirect to Login function
        - Anyone can go to Knowledge Centre since it supports anonymous browsing/logging in to access content
        - If the url doesn't match any routes it will fall-through and redirect back to www.egbc.ca (or stg, dev, test, etc)
        - '/test-app' route and fall-through PageNotFound route have been removed but can be re-imported/added if needed
     */

    return (
        <div className="main-app">
            {ssoCheckComplete ?
                <EnvironmentContext.Provider value={{ environmentData } }>
                    <KeycloakContext.Provider value={{ loggedIn, setLoggedIn }}>
                        <Layout>
                             <Suspense fallback={<LoadingIcon />}>
                                 <Routes>
                                     <Route path='/' element={<Navigate to={launch_app} />} />
                                     <Route path='/audit-portal/*' element={loggedIn ? <AuditPortalApp /> : <LoginPage />} />
                                     <Route path='/cep-reporting' element={loggedIn ? <CEPReportingApp /> : <LoginPage />} />
                                     <Route path='/knowledge-centre/*' element={<KnowledgeCentreApp />} />
                                     <Route path='/student-renewal/' element={<StudentRenewalApp />} />
                                     <Route path='/firm-profile/*' element={loggedIn ? <FirmProfileApp /> : <LoginPage />} />
                                     <Route path='/account-dashboard/' element={loggedIn ? <AccountDashboardApp /> : <LoginPage />} />
                                     <Route path='/membership-confirmation' element={<MembershipConfirmationApp />} />
                                     <Route path='/change-legal-name' element={loggedIn ? <ChangeLegalNameApp /> : <LoginPage />} />
                                     <Route path='/application-status-portal/' element={loggedIn ? <ApplicationStatusPortalApp /> : <LoginPage />} />
                                     <Route path='/registrant-audit-portal/*' element={loggedIn ? <RegistrantAuditPortalApp /> : <LoginPage />} />
                                     <Route path='/career-ads/' element={loggedIn ? <CareerAdApp /> : <LoginPage />} />
                                     <Route path='/purchase-prepaid-ads/' element={loggedIn ? <CareerAdPrepaidApp /> : <LoginPage />} />
                                     <Route path='/career-ad-listings/' element={<CareerAdListingApp />} />
                                     <Route path='/job-postings/' element={<CareerAdJobDetailApp />} />
                                     <Route path='/view-job-postings' element={loggedIn ? <CareerAdViewPostingApp /> : <LoginPage />} />
                                     <Route path='/mentoring-program/*' element={<MentoringProgramApp />} />
                                     <Route path='/online-store/*' element={loggedIn ? <OnlineStoreApp /> : <LoginPage />} />
                                     <Route path='/events' element={<EventsApp />} />
                                     <Route path='/event/' element={<EventDetailsApp />} />
                                     <Route path='/external-event/' element={<ExternalEventDetailsApp />} />
                                     <Route path='/registration-application/*' element={loggedIn ? <MembershipApplicationApp /> : <LoginPage />} />
                                     <Route path='*' element={<HomePage />} />
                                 </Routes>
                             </Suspense>
                        </Layout>
                    </KeycloakContext.Provider>
                </EnvironmentContext.Provider>
            : <div></div> }
        </div>
    );
}

export default App;
